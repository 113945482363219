


























































































































































































































































































































































































































.invalid {
  color: #F56C6C;
}

.pay-card {
  padding: 0 12px;

  &-header {
    margin: 24px 0;
    justify-content: center;

    &-back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-title {
      display: flex;
      flex-direction: column;
      text-align: center;

      &-text {
        color: #2D2E2F;
        font-family: "Raleway SemiBold", serif;
        font-size: 16px;
        margin: 0;
      }

      &-amount {
        color: #2D2E2F;
        font-family: "Raleway Bold", serif;
        font-size: 32px;
        margin: 0;
      }
    }
  }

  &-form {
    &-terms::v-deep {
      margin-bottom: 56px !important;

      .el-checkbox {
        display: flex;
        align-items: center;
        width: 100%;

        &__inner {
          width: 16px;
          height: 16px;
          background: #2D2E2F;
          border: none;
          border-radius: 2px;

          &:after {
            height: 8px;
            width: 4px;
            border: 2px solid #FFFFFF;
            border-left: 0;
            border-top: 0;
            left: 5px;
            top: 2px;
          }
        }

        &__label {
          font-family: "Raleway Medium", serif;
          font-size: 12px;
          line-height: 16px;
          color: #2D2E2F;
          white-space: normal;

          .terms-link {
            color: #B5B5B5;
            text-decoration: underline;
          }
        }
      }
    }

    &-details {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-field {
      &-card::v-deep {
        margin-bottom: 8px !important;

        label {
          &:before {
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
          }
        }
      }

      &-card {
        &-icons {
          display: flex;
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translate(0, -50%);

          img:last-child {
            margin-left: 4px;
          }
        }
      }

      &-exp {
        width: 100%;
      }

      &-cvc {
        width: 100%;
        margin-left: 8px;

        &-icon {
          display: flex;
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }

  &-powered-by {
    text-align: center;
    margin-bottom: 24px;

    &-title {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Raleway Medium", serif;
        font-size: 12px;
        line-height: 16px;
        color: #A3A4AC;

        img {
          margin-left: 4px;
        }
      }
    }

    &-links {
      margin-top: 8px;

      a {
        font-family: "Raleway Medium", serif;
        font-size: 12px;
        color: #A3A4AC;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}

#credit-card, #card-exp, #card-cvc {
  border: 1px solid #E3E5E5;
  border-radius: 8px;
  padding: 14px 16px;
}

.el-form-item::v-deep {
  margin-bottom: 24px;

  input {
    font-family: "Raleway Bold", serif;
    font-size: 16px;

    &::placeholder {
      font-family: "Raleway Regular", serif;
    }
  }
}
